import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSelect from "@/components/Content/CardSelect.vue";
import RadioButton from "@/components/Content/RadioButton.vue";
import { getError } from "@/utils/resolveObjectArray";
import { Getter } from "vuex-class";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { getPermisionReport } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";
import { getLast2FullYear } from "@/utils/convert";
import { getDateByYear } from "@/services/date-service";

@Component({
  components: {
    CardAutocomplete,
    CardSelect,
    NotPermission,
    RadioButton,
  },
  mixins: [],
})
export default class ReportType extends Vue {
  /**
   * PROPS
   */
  //@Prop() readonly value!: string;

  //@Prop() readonly items!: [];

  @Prop({ default: false }) readonly required!: boolean;

  @Prop({ default: false }) readonly disabled!: boolean;

  /**
   * GETTERS
   */
  @Getter("report_v2/storeReport") getStoreReport!: ReportDataEntity;
  @Getter("report_v2/storeResources") getStoreResources!: ResourceDataEntity;
  @Getter("profile/getAbility") getAbility;

  /**
   * EMITTERS
   */
  @Emit("input")
  handleOnInput(event: string | null) {
    return event;
  }

  /**
   * Computed
   */
  public get getPermission() {
    return getPermisionReport();
  }

  public get getYears() {
    return getLast2FullYear(
      this.getStoreReport.isReach() ||
      this.getStoreReport.isMaids() ||
      this.getStoreReport.isSotreAtributtion() ||
      this.getStoreReport.isInsigths() ||
        this.getStoreReport.isPerformance(),
    );
  }

  public get canReportTypeSP() {
    return this.getAbility.can(this.getPermission.actions["index_report_sp"], this.getPermission.subject);
  }
  /**
   * Actions
   */
  public getError(index: "report_type") {
    return getError(this.getErrors, index);
  }

  private getErrors() {
    return this.$store.state.proccess.errors;
  }

  /**
   * Actions Handlers
   */

  /** Life Cicle */
  public async mounted() {}

  /**
   * WATCHERS
   */
  @Watch("getStoreReport.report_type")
  async onChangeReportType(report_type: string) {

    if (this.getStoreReport.isReach()) {
      this.getStoreReport.setCurrentYear();
    }
    
    this.$emit("report-selected", report_type);
  }

  @Watch("getStoreReport.report_year")
  async onChangeYear(year: number) {
    this.getStoreReport.onChangeYear();

    const isCustom = this.getStoreReport.isCustom();

    this.getStoreReport.setDateData({
      start_date: isCustom ? getDateByYear("start_date", year) : "",
      end_date: isCustom ? getDateByYear("end_date", year) : "",
    });
  }

  @Watch("getStoreReport.data_range")
  async onChangeDataRange(val: string | null) {
    const isCustom = this.getStoreReport.isCustom();

    const year = this.getStoreReport.report_year;

    this.getStoreReport.setDateData({
      start_date: isCustom ? getDateByYear("start_date", year) : "",
      end_date: isCustom ? getDateByYear("end_date", year) : "",
    });
  }
}
